import { graphql, Link, useStaticQuery } from "gatsby"
import PropTypes from "prop-types"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faMediumM, faRedditAlien, faTwitter } from "@fortawesome/free-brands-svg-icons"
import React from "react"

const FooterSectionTitle = ({ title }) => (
  <h2 className="text-xl font-semibold text-center">
    {title}
  </h2>
)

FooterSectionTitle.propTypes = {
  title: PropTypes.string
}

const FooterSectionLink = ({ to, children }) => (
  <Link className="text-gray-300 hover:underline hover:text-white" to={to}>{children}</Link>
)

const SocialMediaIconLink = ({ to, icon }) => (
  <a
    className="rounded-full border-4 border-white m-4 w-12 h-12 flex justify-center items-center hover:bg-white hover:text-black"
    href={to}
  >
    <FontAwesomeIcon icon={icon} size="lg" />
  </a>
)

const Footer = (_props) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            anchorUrl
            flashLockerUrl
          }
        }
      }
    `
  )

  return (
    <footer className="bg-black py-20 text-white">
      <div className="container mx-auto">
        <div className="flex flex-col md:flex-row justify-between">
          <div className="md:w-2/6 px-8 md:px-0">
            <FooterSectionTitle title="Stay in touch" />
            <div className="flex flex-row justify-center mt-6">
              <SocialMediaIconLink to="https://medium.com/lockerx" icon={faMediumM} />
              <SocialMediaIconLink to="https://twitter.com/lockerx_io" icon={faTwitter} />
              <SocialMediaIconLink to="https://reddit.com/r/lockerx" icon={faRedditAlien} />
            </div>
          </div>
          <div className="md:w-2/6  px-8 md:px-0 leading-loose">
            <FooterSectionTitle title="Links" />
            <ul>
              <li>
                <FooterSectionLink to="/">Home</FooterSectionLink>
              </li>
            </ul>
          </div>
        </div>
        <div className="pt-24 flex flex-col md:flex-row justify-between text-gray-500">
          <div className="text-center md:text-left">
            © {new Date().getFullYear()} <a className="hover:underline hover:text-gray-200"
                                            href="https://www.longrockcapital.co.uk">Long Rock Capital</a>.
          </div>
          <div>
            <ul className="flex flex-row justify-center md:justify-between">
              <li>
                <Link className="hover:underline hover:text-gray-200" to="/privacy-policy">Privacy Policy</Link>
              </li>
              <li>
                <Link className="ml-8 hover:underline hover:text-gray-200" to="/terms-and-conditions">Terms and
                  Conditions</Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  )
}

Footer.propTypes = {}

Footer.defaultProps = {}

export default Footer
