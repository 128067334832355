import React from "react"
import { Link } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronDown, faBars } from "@fortawesome/free-solid-svg-icons"
import { useStaticQuery, graphql } from "gatsby"

const HeaderDropdownItem = ({ to, title, subtitle, tag }) => {
  let content = (
    <React.Fragment>
      <h2>
        <span className="font-semibold text-black">{title}</span>
        {tag && <span className="text-xs bg-teal-400 rounded ml-4 px-4 py-1">{tag}</span>}
      </h2>
      <p className="text-gray-800">{subtitle}</p>
    </React.Fragment>
  )

  return (
    <li className="p-2 px-4 hover:bg-gray-200">
      {to !== undefined ? <a href={to}>{content}</a> : <div>{content}</div>}
    </li>
  )
}

const HeaderContent = ({onMobileMenuClick}) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            anchorUrl
            flashLockerUrl
          }
        }
      }
    `
  )

  let [productVisible, setProductVisible] = React.useState(false)
  let [mobileMenuVisible, setMobileMenuVisible] = React.useState(false)

  const sections = [{
    title: "Flash Locker",
    subtitle: "Send payments",
    to: site.siteMetadata.flashLockerUrl
  }, {
    title: "Anchor",
    to: site.siteMetadata.anchorUrl,
    subtitle: "Convert from crypto to Stellar and back"
  }]

  return (
    <React.Fragment>
      <div className="container mx-auto py-6 px-8 md:px-0 flex md:flex-row justify-between">
        <div className="flex flex-row md:flex-col justify-center">
          <Link className="py-2" to="/">
            <h1 className="font-bold text-xl">LockerX</h1>
          </Link>
        </div>
        <div className="md:hidden">
          <button
            className="p-2"
            onClick={(evt) => {
              const newVisible = !mobileMenuVisible
              setMobileMenuVisible(newVisible)
              onMobileMenuClick(newVisible)
            }}
          >
            <FontAwesomeIcon icon={faBars} size="1x" />
          </button>
        </div>
        <div className="md:flex flex-row justify-end hidden">
          <ul className="flex flex-row">
            <li>
              <div className="px-4 py-2 ml-8">
                <Link to="/contact">Contact Us</Link>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div
        className={`w-full absolute z-50 bg-white border-b border-gray-500 container mx-auto pb-6 px-8 mb-8 md:hidden ${mobileMenuVisible ? "" : "hidden"}`}>
        <div>
          <h2 className="text-xl font-semibold">Products</h2>
          <ul className="leading-loose">
            {
              sections.map((section, idx) => {
                let content
                if (section.to !== undefined) {
                  content = <a href={section.to}>{section.title}</a>
                } else {
                  content = <span>{section.title} <span
                    className="bg-teal-400 rounded text-xs px-2 py-1 ml-4">{section.tag}</span></span>
                }

                return (
                  <li key={idx}>
                    {content}
                  </li>
                )
              })
            }
          </ul>
        </div>
      </div>
    </React.Fragment>
  )
}

export default HeaderContent
